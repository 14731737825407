.notifierContainer {
  padding: 16px 25px;
  border-top: 1px solid var(--main-base-white, #e7e7e7);
  border-bottom: 1px solid var(--main-base-white, #e7e7e7);
  margin: 32px 0 27px;
}

.notifierContainer h4 {
  font-size: 16px;
  font-weight: 700;
  color: var(--header-color, #161513);
  margin: 0 0 0 14px;
}

.notifierContainer .icon {
  float: left;
  margin: 0 5px 0 0;
  cursor: pointer;
}
.notifierContainer p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #757573;
  margin-top: 5px;
}
.notifierMessage {
  white-space: pre-line;
}
